import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 29;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>好きだったら長く続けられるはず</li>
        <li>趣味から仕事になると嫌いになる</li>
        <li>好きなことを続けるためにはハードルがある</li>
        <li>趣味を続けるにはまずパッション</li>
        <li>他人は自分のことをあまり気にしてない</li>
        <li>一つのことに対する絶えない愛情がすごい</li>
        <li>実はみんなバランス派！？</li>
        <li>趣味を続けるには人が大切</li>
        <li>釣りは一人で行ってもつまらない</li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
